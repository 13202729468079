@import url('https://fonts.googleapis.com/css2?family=Licorice&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shizuru&display=swap');

@font-face {
  font-family: Cartoon;
  src: url('../assets/fonts/ArbFONTS-VIP-Cartoon-VIP-Cartoon.ttf');
}

@font-face {
  font-family: Agent;
  src: url('../assets/fonts/AGENTORANGE.TTF');
}

@font-face {
  font-family: Animal;
  src: url('../assets/fonts/AnimalSilence.otf');
}

@font-face {
  font-family: Newtype;
  src: url('../assets/fonts/Newtype.otf');
}

::selection {
  color: var(--white);
  background: var(--red);
}

body {
  cursor: url('../assets/cursor/default.png'), default;
}

a, button {
  cursor: url('../assets/cursor/pointer.png'), pointer;
}